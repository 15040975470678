/* @flow */
import type { Node } from 'react';
import styled from '@emotion/styled';
import { ThemeProvider } from '@eyeem-ui/theme';
import { Button, Flex, Box } from '@eyeem-ui/atoms';
import {
  Download,
  LightboxAdd,
  LightboxMinus,
  Cart,
  Photo,
} from '@eyeem-ui/icons';

const AbsoluteLeft = styled('span')`
  position: absolute;
  left: 0;
  /* IE11 */
  top: 50%;
  transform: translateY(-50%);
`;

const RelativeFlex = styled(Flex)`
  width: 100%;
  position: relative;
  width: 100%;
`;

const getIcon = (icon) => {
  const size = 16;

  switch (icon) {
    case 'download':
      return <Download size={size} />;
    case 'addToLightbox':
      return <LightboxAdd size={size} />;
    case 'isInLightbox':
      return <LightboxMinus size={size} />;
    case 'cart':
      return <Cart size={size} />;
    case 'photo':
      return <Photo size={size} />;
    case 'google':
      return <img src="/node-static/img/logos/google.svg" alt="google logo" />;

    default:
      return null;
  }
};

// https://eyeem.github.io/eyeem-ui/?path=/story/icons-icon-overview--example
type IconName =
  | 'download'
  | 'addToLightbox'
  | 'isInLightbox'
  | 'cart'
  | 'google';

function IconButton(props: { children: Node, icon: IconName }) {
  const { icon, children, ...buttonProps } = props;

  return (
    <ThemeProvider>
      <Button {...buttonProps}>
        <RelativeFlex alignItems="center">
          <AbsoluteLeft>{getIcon(icon)}</AbsoluteLeft>
          <Box flex={1}>{children}</Box>
        </RelativeFlex>
      </Button>
    </ThemeProvider>
  );
}

export default IconButton;
