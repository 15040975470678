/* @flow */
import t from 'counterpart';
import styled from '@emotion/styled';
import { Text, Flex, Box } from '@eyeem-ui/atoms';

const StyledSeparator = styled(Flex)`
  align-items: center;
  width: 100%;
  height: 8px;
  display: inline-block;
  border-bottom: 2px solid ${({ theme }) => theme.colors.grey20};
`;

const StyledBox = styled(Box)`
  min-width: auto;
`;

function OrSeparator() {
  return (
    <Flex py={3} justifyContent="center">
      <StyledSeparator></StyledSeparator>
      <StyledBox px={2}>
        <Text as="div" variant="title6" bold textTransform="uppercase">
          {t('authForms.or')}
        </Text>
      </StyledBox>
      <StyledSeparator></StyledSeparator>
    </Flex>
  );
}

export default OrSeparator;
