/* @flow */

import t from 'counterpart';
import { Text } from '@eyeem-ui/atoms';

const Headline = (props: { text: string, translationKey?: any }) => {
  return (
    <Text as="h1" variant="title3">
      {t(props.text, props.translationKey)}
    </Text>
  );
};

Headline.defaultProps = {
  translationKey: {},
};

export default Headline;
