// @flow
import { useState } from 'react';
import t from 'counterpart';

import IconButton from '../iconButton.jsx';
import {
  COOKIE_RECENT_AUTH_KEY,
  COOKIE_RECENT_AUTH_MAX_AGE,
  COOKIE_RECENT_AUTH_VALUE,
  OAUTH,
  USER_TYPE_SELFSERVE,
  AUTH_INTENT_LOGIN,
} from '../../../constants/misc';
import { setCookieFor } from '../../../helpers/cookies';
import { track } from '../../../helpers/tools';

type Props = {
  intent: string,
  isCheckoutAuth?: boolean,
  loginViaOauth: (OpenIdAuthUrlPayload) => Promise<{
    authUrl?: string,
  }>,
};

function GoogleLogin({ loginViaOauth, intent, isCheckoutAuth }: Props) {
  const [isPending, setIsPending] = useState(false);

  const buttonLabel =
    intent === AUTH_INTENT_LOGIN
      ? 'authForms.openId.googleButton.loginLabel'
      : 'authForms.openId.googleButton.signupLabel';

  const startGoogleSignup = () => {
    setIsPending(true);

    track({
      eventType: 'click_inbound',
      eventName: 'google_auth_button',
      eventAction: 'click_button',
      eventLabel: t(buttonLabel),
      eventPosition: undefined,
    });

    if (!isCheckoutAuth) {
      setCookieFor(
        COOKIE_RECENT_AUTH_KEY,
        COOKIE_RECENT_AUTH_VALUE,
        COOKIE_RECENT_AUTH_MAX_AGE
      );
    }

    return loginViaOauth({
      provider: OAUTH.GOOGLE,
      // TODO: Determine photographer somehow? Session cookie?
      type: USER_TYPE_SELFSERVE, // isPhotographer? USER_TYPE_SELFSERVE : USER_TYPE_PHOTOGRAPHER,
      intent,
    })
      .then(({ authUrl }) => {
        if (!authUrl) {
          throw new Error('No authUrl received.');
        }

        setIsPending(false);

        window.location.href = authUrl;
      })
      .catch(() => {
        setIsPending(false);
      });
  };

  return (
    <IconButton
      variant="secondary"
      disabled={isPending}
      icon="google"
      fullWidth
      onClick={startGoogleSignup}>
      {t(buttonLabel)}
    </IconButton>
  );
}

GoogleLogin.defaultProps = {
  isCheckoutAuth: false,
};

export default GoogleLogin;
