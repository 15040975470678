/* @flow */
/*  Wrapper for Facebook Login button */
/* eslint-env browser */
/** @jsxRuntime classic */
/** @jsx jsx */

import { Component } from 'react';
import { jsx } from '@theme-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { Button, Box } from '@eyeem-ui/atoms';
import { Facebook } from '@eyeem-ui/icons';

const FACEBOOK_APP_ID = '138146182878222'; // production
// const FACEBOOK_APP_ID = '114388882073924'; // dev

type Props = {
  onSuccess: Function,
  onFailure: Function,
  setAuthRef: Function,
  loginViaFacebook: LoginViaFacebook,
  label: string,
  className?: string,
  authRef?: string,
  isMobile: boolean,
  searchData: SearchData,
};

class FacebookAuth extends Component<Props> {
  callback = (response: {
    accessToken: string,
    expiresIn: number,
    id: string,
    name: string,
    signedRequest: string,
    userID: string,
  }) => {
    if (!response.accessToken) {
      // console.log('Facebook Login failed', response);
      return this.props.onFailure();
    }
    return this.props
      .loginViaFacebook({
        fbAccessToken: response.accessToken,
        fbUserId: response.id,
        fbAccessTokenExpires: response.expiresIn,
        searchData: this.props.searchData,
      })
      .then(this.props.onSuccess)
      .catch(this.props.onFailure);
  };

  onClick = () => {
    if (this.props.authRef) {
      this.props.setAuthRef({ authRef: this.props.authRef });
    }
  };

  render() {
    return (
      <FacebookLogin
        isMobile={this.props.isMobile}
        onClick={this.onClick}
        appId={FACEBOOK_APP_ID}
        scope="email"
        callback={this.callback}
        render={(renderProps) => (
          <Button
            variant="secondary"
            onClick={renderProps.onClick}
            sx={{
              position: 'relative',
            }}
            fullWidth>
            <Box
              sx={{
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}>
              <Facebook color="#1877F2" />
            </Box>
            {this.props.label}
          </Button>
        )}
      />
    );
  }
}

export default FacebookAuth;
