/* @flow */
/*  Login Form as displayed on /login */
/* eslint-env browser */
import type { Node } from 'react';

import { Component } from 'react';
import t from 'counterpart';
import { Box } from '@eyeem-ui/atoms';
import { Text } from '@eyeem-ui/atoms';

import { SubmissionError } from 'redux-form';

import FacebookAuth from '../facebookAuth/';
import Link from '../link/';
import Headline from '../../authForms/headline.jsx';
import Form from './form.jsx';
import {
  COOKIE_RECENT_AUTH_KEY,
  COOKIE_RECENT_AUTH_MAX_AGE,
  COOKIE_RECENT_AUTH_VALUE,
} from '../../../constants/misc';
import withRouter from '../../../helpers/hoc/withRouter.jsx';
import { isSeller } from '../../../helpers/accounts';
import { setCookieFor } from '../../../helpers/cookies';
import { getHomeUrl, getProductionsRedirect } from '../../../helpers/tools';

type Props = {
  authRef: string,
  buyer?: boolean,
  callback: Function,
  className: string,
  close: Function,
  formTypeToggle?: Node,
  headline: string,
  isAuthPending: boolean,
  isCheckoutAuth?: boolean,
  isInModal?: boolean,
  isMobile: boolean,
  login: Login,
  navigate: Function,
  openModal: Function,
  reloadAfterAuth: boolean,
  searchData: SearchData,
  showNotification: Function,
};

type State = {
  customError: string,
};

class LoginFormWrapper extends Component<Props, State> {
  static defaultProps = {
    isCheckoutAuth: false,
  };
  state = {
    customError: '',
  };
  submit = (values: {}) => {
    if (!this.props.isCheckoutAuth) {
      setCookieFor(
        COOKIE_RECENT_AUTH_KEY,
        COOKIE_RECENT_AUTH_VALUE,
        COOKIE_RECENT_AUTH_MAX_AGE
      );
    }

    return this.props
      .login({
        ...values,
        callback: this.loginCallback,
        searchData: this.props.searchData,
      })
      .then(this.loginCallback)
      .catch((err) => {
        this.setState({
          customError:
            err?.errors?.captcha || t('authForms.login.errorConflict'),
        });
        // the errors get thrown is redux.js handleAuthResponseError.
        if (err instanceof Error) {
          throw err;
        }
        // default error message
        throw new SubmissionError({
          _error: t('authForms.login.errorConflict'),
        });
      });
  };

  loginCallback = (res: AuthResponse) => {
    if (this.props.reloadAfterAuth) {
      return window.location.reload();
    }
    if (this.props.callback) {
      return this.props.callback(res.authUser);
    }
    let redirectUrl = this.props.authRef;

    const validProductionsRedirect = getProductionsRedirect(
      redirectUrl,
      window.location.search
    );

    if (validProductionsRedirect) {
      window.location.href = validProductionsRedirect;

      return null;
    }

    if (this.props.authRef === '/') {
      redirectUrl = getHomeUrl({
        authUser: res.authUser,
      });
    }

    if (this.props.authRef === '/market/sell/start' && isSeller(res.authUser)) {
      // we redirect users to /upload after seller signup, but if
      // the user is already a seller, we can just skip the seller signup
      redirectUrl = '/upload/photos';
    }

    return this.props.navigate(redirectUrl);
  };

  showFacebookAuthError = () =>
    this.props.showNotification({
      notification: 'loginErrorUnknown',
      type: 'warning',
    });

  render() {
    const FormTypeToggle = this.props.formTypeToggle;
    const showFacebookAuth =
      !this.props.buyer || (!this.props.isMobile && !this.props.isInModal);

    return (
      <div className={this.props.className}>
        <Box py={2}>
          <Box py={2}>
            <Headline text={this.props.headline} />
          </Box>

          {this.props.formTypeToggle ? (
            <Box>
              <FormTypeToggle />
            </Box>
          ) : null}
        </Box>

        {showFacebookAuth && (
          <Box mt={3}>
            <FacebookAuth
              label={t('authForms.login.facebook')}
              onSuccess={this.loginCallback}
              onFailure={this.showFacebookAuthError}
            />
          </Box>
        )}

        <Form
          buyer={this.props.buyer}
          customError={this.state.customError}
          isAuthPending={this.props.isAuthPending}
          isCheckoutAuth={this.props.isCheckoutAuth}
          isMobile={this.props.isMobile}
          onChange={(fields, _, __, previousFields) => {
            // only reset the errorMessage if any fiels except captchaResponse one changes
            if (fields.recaptchaResponse !== previousFields.recaptchaResponse) {
              return null;
            }
            this.setState({ customError: '' });
          }}
          onSubmit={this.submit}
        />

        <Box pt={4}>
          <Link
            onClick={() => this.props.close && this.props.close()}
            href="/password"
            title={t('authForms.login.forgotPassword')}>
            <Text bold>{t('authForms.login.forgotPassword')}</Text>
          </Link>
        </Box>
      </div>
    );
  }
}

LoginFormWrapper.defaultProps = {
  buyer: true,
  formTypeToggle: null,
  headline: 'authForms.login.headline',
};

export default withRouter(LoginFormWrapper);
