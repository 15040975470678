/* @flow */
import { connect } from 'react-redux';
import LoginFormWrapper from './loginFormWrapper.jsx';

import {
  loginThunk,
  loginViaFacebookThunk,
  openModal,
  showNotificationAndDismiss,
} from '../../../helpers/actionCreators/redux';

import {
  getAuthRef,
  getIsAuthPending,
  getIsMobile,
  getIsPhotographer,
  getSearchData,
} from '../../../reducers/';

export default connect(
  (state: RootReducerState, ownProps: { authRef?: string }) => ({
    authRef: ownProps.authRef || getAuthRef(state),
    isAuthPending: getIsAuthPending(state),
    isMobile: getIsMobile(state),
    isPhotographer: getIsPhotographer(state),
    searchData: getSearchData(state),
  }),
  {
    login: loginThunk,
    loginViaFacebook: loginViaFacebookThunk,
    showNotification: showNotificationAndDismiss,
    openModal,
  }
)(LoginFormWrapper);
