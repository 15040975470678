import styled from '@emotion/styled';
import { Button, Spinner } from '@eyeem-ui/atoms';

const StyledButton = styled(Button)`
  position: relative;
  height: 40px;
`;

const SubmitButton = (props) => {
  return (
    <StyledButton
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      fullWidth
      customProps={{
        'data-test-id': props['data-test-id'],
      }}>
      {props.spinner ? (
        <Spinner color="grey0" size="24" />
      ) : (
        props.text || props.children
      )}
    </StyledButton>
  );
};

export default SubmitButton;
