/* @flow */
/* eslint-env browser */
/** @jsxRuntime classic */
/** @jsx jsx */
import { Component, Fragment } from 'react';
import { jsx } from 'theme-ui'; // eslint-disable-line
import t from 'counterpart';
import { Field, reduxForm } from 'redux-form';
import type { FormProps } from 'redux-form';
import { Text, Box, Flex } from '@eyeem-ui/atoms';

import { Captcha, Checkbox, InputPassword, Input } from '../../formElements/';
import SubmitButton from '../../authForms/submitButton.jsx';
import GoogleLogin from '../googleLogin/';
import withRouter from '../../../helpers/hoc/withRouter';

import T from '../translate.jsx';
import OrSeparator from '../orSeparator.jsx';
import Link from '../link/';

import { AUTH_INTENT_LOGIN } from '../../../constants/misc';

import validate, {
  VALIDATOR_UNICODE_STRING,
  VALIDATOR_REQUIRED,
  VALIDATOR_BCG_EMAIL,
} from '../../../helpers/validators';

import { TEST_LOGIN_BUTTON } from '../../../constants/pageObjectSelectors';

const required = validate(VALIDATOR_REQUIRED);
const unicodeString = validate(VALIDATOR_UNICODE_STRING);

type Props = {
  buyer?: boolean,
  className: string,
  customError: string,
  isAuthPending: boolean,
  isCheckoutAuth?: boolean,
} & FormProps;

type State = {
  oauthError: string,
};

class LoginFormForm extends Component<Props, State> {
  static defaultProps = {
    isCheckoutAuth: false,
  };

  state = {
    oauthError: undefined,
  };

  componentDidMount() {
    // check for query param ?oauth=404. is set in routes.js after google auth login attempt with creator email
    const oauthError = this.props.query.oauth;
    const errorMessages = {
      409: t('authForms.openId.emailAlreadyRegistered'),
      410: t('authForms.openId.creatorLoginError'),
    };
    if (oauthError) {
      this.setState({ oauthError: errorMessages[oauthError] }, () => {
        window &&
          window.history.replaceState(
            {},
            '',
            window.location.origin + window.location.pathname
          );
      });
    }
  }

  bcgEmail = validate(
    VALIDATOR_BCG_EMAIL,
    <T
      with={{
        link: (
          <Link href="https://bcg.eyeem.com">
            <T
              noTranslate
              component={Text}
              variant="title6"
              sx={{ textDecoration: 'underline' }}
              bold>
              https://bcg.eyeem.com
            </T>
          </Link>
        ),
      }}>
      forms.error.VALIDATOR_BCG_EMAIL
    </T>
  );

  render() {
    return (
      <Fragment>
        <Flex pb={2} flexDirection="column">
          {this.props.buyer && (
            <Box py={2}>
              <GoogleLogin
                intent={AUTH_INTENT_LOGIN}
                isCheckoutAuth={this.props.isCheckoutAuth}
              />
            </Box>
          )}
          {/* ErrorMessage if we have a redirect after failed google auth login attempt (creator account login attempt) OR signup attempt with already registered email */}
          {this.state.oauthError && (
            <Box pt={1}>
              <Text color="red50">{this.state.oauthError}</Text>
            </Box>
          )}
        </Flex>
        {this.props.buyer && <OrSeparator />}
        <form onSubmit={this.props.handleSubmit}>
          <Field
            component={Input}
            placeholder={t('authForms.login.email')}
            label={t('authForms.login.email')}
            name="email"
            type="text"
            validate={[required, unicodeString, this.bcgEmail]}
            autoComplete="email"
          />
          <Field
            component={InputPassword}
            placeholder={t('authForms.login.password')}
            label={t('authForms.login.password')}
            name="password"
            validate={required}
            autoComplete="off"
          />
          <Box pt={3} pb={4}>
            <Field
              component={Checkbox}
              name="rememberMe"
              label={t('authForms.login.rememberMe')}
            />
          </Box>
          <Captcha customError={this.props.customError} />
          {this.props.customError && (
            <Box pb="3">
              <Text color="red50">{this.props.customError}</Text>
            </Box>
          )}
          <SubmitButton
            type="submit"
            size="large"
            fullWidth
            spinner={this.props.isAuthPending}
            disabled={this.props.invalid || this.props.isAuthPending}
            data-test-id={TEST_LOGIN_BUTTON}>
            {t('authForms.login.submit')}
          </SubmitButton>
        </form>
      </Fragment>
    );
  }
}

LoginFormForm.defaultProps = {
  buyer: true,
};

export default reduxForm({
  form: 'regularLogin',
})(withRouter(LoginFormForm));
