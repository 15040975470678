/* @flow */
/* Signup Form as displayed, for example, on /signup/buyer */
import type { Node } from 'react';

import { Component, Fragment } from 'react';
import t from 'counterpart';
import { SubmissionError } from 'redux-form';

import { Box } from '@eyeem-ui/atoms';

import Form from './form';
import Headline from '../../authForms/headline';
import FacebookAuth from '../facebookAuth/';
import OrSeparator from '../orSeparator';
import {
  COOKIE_RECENT_AUTH_KEY,
  COOKIE_RECENT_AUTH_MAX_AGE,
  COOKIE_RECENT_AUTH_VALUE,
} from '../../../constants/misc';
import { isSeller } from '../../../helpers/accounts';
import { setCookieFor } from '../../../helpers/cookies';
import { track, getHomeUrl } from '../../../helpers/tools';
import withRouter from '../../../helpers/hoc/withRouter';

type Props = {
  authRef: string,
  buyer: boolean, // buyer props tells us if we are on buyer signup
  callback: Function, // e.g. to close a modal
  className: string,
  eventPosition: string,
  formTypeToggle?: Node,
  headline: string,
  isAuthPending: boolean,
  isCheckoutAuth?: boolean,
  isInModal: boolean,
  isMobile: boolean,
  openModal: OpenModal,
  searchData: SearchData,
  showNotification: Function,
  signup: Signup,
} & WithRouterProps;
type State = {
  customError: string,
};

class SignupFormWrapper extends Component<Props, State> {
  static defaultProps = {
    isCheckoutAuth: false,
  };
  state = {
    customError: '',
  };
  submit = (values: {}) => {
    if (!this.props.isCheckoutAuth) {
      setCookieFor(
        COOKIE_RECENT_AUTH_KEY,
        COOKIE_RECENT_AUTH_VALUE,
        COOKIE_RECENT_AUTH_MAX_AGE
      );
    }

    return this.props
      .signup({
        ...values,
        buyer: this.props.buyer,
        searchData: this.props.searchData,
      })
      .then(this.signupCallback)
      .catch((err) => {
        this.setState({
          customError:
            err?.errors?.captcha ||
            err?.errors?.email ||
            t('notifications.signupErrorUnknown'),
        });
        this.track('invalid');

        // the errors get thrown is redux.js handleAuthResponseError.
        if (err instanceof Error) {
          throw err;
        }
        throw new SubmissionError({
          _error: t('notifications.signupErrorUnknown'),
        });
      });
  };

  track = (suffix: 'valid' | 'invalid') => {
    const type = this.props.buyer ? 'buyer' : 'photographer';
    return track({
      eventType: t('tracking.eventType.inbound'),
      eventAction: t('tracking.eventAction.button'),
      eventName: t(`authForms.signup.submit.eventName.${type}`, { suffix }),
      eventLabel: t('authForms.signup.submit.label'),
      eventPosition: this.props.eventPosition,
    });
  };

  signupCallback = (res: AuthResponse) => {
    this.track('valid');

    if (this.props.callback) {
      return this.props.callback(res.authUser);
    }

    const authRef =
      this.props.authRef === '/' || isSeller(res.authUser)
        ? getHomeUrl({ authUser: res.authUser })
        : this.props.authRef;

    return this.props.navigate(authRef);
  };

  showFacebookAuthError = () =>
    this.props.showNotification({
      notification: 'signupErrorUnknown',
      type: 'warning',
    });

  render() {
    const FormTypeToggle = this.props.formTypeToggle;
    return (
      <div className={this.props.className}>
        <Box py={2}>
          <Box py={2}>
            <Headline text={this.props.headline} />
          </Box>

          {this.props.formTypeToggle ? (
            <Box>
              <FormTypeToggle />
            </Box>
          ) : null}
        </Box>
        {!this.props.isMobile && !this.props.buyer && (
          <Fragment>
            <Box mb={2} mt={3}>
              <FacebookAuth
                label={t('authForms.signup.facebook')}
                onSuccess={this.signupCallback}
                onFailure={this.showFacebookAuthError}
              />
            </Box>
            <OrSeparator />
          </Fragment>
        )}

        <Box>
          <Form
            buyer={this.props.buyer}
            customError={this.state.customError}
            isAuthPending={this.props.isAuthPending}
            isCheckoutAuth={this.props.isCheckoutAuth}
            isInModal={this.props.isInModal}
            onChange={(fields, _, __, previousFields) => {
              // only reset the errorMessage if any fiels except captchaResponse one changes
              if (
                fields.recaptchaResponse !== previousFields.recaptchaResponse
              ) {
                return null;
              }
              this.setState({ customError: '' });
            }}
            onSubmit={this.submit}
            withNewsletterSignup
          />
        </Box>
      </div>
    );
  }
}

export default withRouter(SignupFormWrapper);
