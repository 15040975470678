// @flow
/** @jsxRuntime classic */
/** @jsx jsx */
import { Component, Fragment } from 'react';
import { jsx } from '@theme-ui/core'; // eslint-disable-line
import { Field, reduxForm, clearSubmitErrors, change } from 'redux-form';
import t from 'counterpart';
import { Text, Box } from '@eyeem-ui/atoms';
import SubmitButton from '../../authForms/submitButton.jsx';
import Link from '../link/';
import OrSeparator from '../orSeparator.jsx';

import validate, {
  VALIDATOR_REQUIRED,
  VALIDATOR_MIN_LENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_BCG_EMAIL,
} from '../../../helpers/validators';

import { track } from '../../../helpers/tools';

import { AUTH_INTENT_SIGNUP } from '../../../constants/misc';

import T from '../translate.jsx';

import { Input, InputPassword, Captcha, Checkbox } from '../../formElements/';
import GoogleLogin from '../googleLogin/';
import { TEST_SIGNUP_BUTTON } from '../../../constants/pageObjectSelectors';

const required = validate(VALIDATOR_REQUIRED);

const email = validate(VALIDATOR_EMAIL);

const getPasswordValidation = (value = null) => {
  const passwordValidations = [VALIDATOR_MIN_LENGTH].map((item) => ({
    valid: typeof validate(item)(value) === 'undefined',
    text: t(`forms.label.password.${item}`),
  }));
  return passwordValidations;
};

type Props = {
  buyer?: boolean, // buyer prop tells us if we are on the buyer signup
  change: Function,
  customError: string,
  dispatch?: Function,
  handleSubmit?: Function,
  isAuthPending?: boolean,
  isCheckoutAuth?: boolean,
  isEnterpriseSignup?: boolean,
  isModal: boolean,
  prefilledEmail: string,
  withNewsletterSignup?: boolean,
  withPasswordToggle?: boolean,
} & FormProps;

type Validation = {
  valid: true | false, // :(
  text: string,
};

type State = {
  passwordValidations: { [string]: Validation },
  hasTrackedFormStart: boolean,
};

const initialPasswordState = getPasswordValidation();

class SignupForm extends Component<Props, State> {
  static defaultProps = {
    isCheckoutAuth: false,
  };

  state = {
    passwordValidations: initialPasswordState,
    hasTrackedFormStart: false,
  };

  bcgEmail = validate(
    VALIDATOR_BCG_EMAIL,
    <T
      with={{
        link: (
          <Link href="https://bcg.eyeem.com">
            <T
              noTranslate
              component={Text}
              variant="title6"
              sx={{ textDecoration: 'underline' }}
              bold>
              https://bcg.eyeem.com
            </T>
          </Link>
        ),
      }}>
      forms.error.VALIDATOR_BCG_EMAIL
    </T>
  );

  trackFormStart = (value) => {
    if (!this.state.hasTrackedFormStart && value !== '') {
      const trackingEvent = this.props.isInModal
        ? {
            eventType: 'click_inbound',
            eventName: 'ecom_modal_form_start',
            eventAction: 'form_start',
            eventLabel: '',
            eventPosition: 'modal_content',
          }
        : {
            eventType: 'click_inbound',
            eventName: this.props.buyer
              ? 'buyerSignup_form_start'
              : 'creatorSignup_form_start',
            eventAction: 'form_start',
            eventLabel: '',
            eventPosition: this.props.buyer ? 'buyer_signup' : 'creator_signup',
          };
      track(trackingEvent);
      this.setState({ hasTrackedFormStart: true });
    }
  };

  setPasswordValidation = (value) => {
    this.trackFormStart(value);
    this.setState({ passwordValidations: getPasswordValidation(value) });
  };

  syncWithLogin = (event, newValue) => {
    this.trackFormStart(newValue);
    this.props.dispatch(change('login', 'email', newValue));
  };

  componentDidUpdate(prevProps) {
    if (prevProps.prefilledEmail !== this.props.prefilledEmail) {
      this.props.change('signup', 'email', this.props.prefilledEmail);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(clearSubmitErrors('signup'));
  }

  render() {
    return (
      <Fragment>
        {/* We will reintroduce google auth as soon as enterprise login is available */}

        {/* Only show Google Signup for buyers until Locksmith supports photographer signup*/}
        {this.props.buyer && !this.props.isEnterpriseSignup && (
          <Fragment>
            <Box mb={2} mt={3}>
              <GoogleLogin
                intent={AUTH_INTENT_SIGNUP}
                isCheckoutAuth={this.props.isCheckoutAuth}
              />
            </Box>
            <OrSeparator />
          </Fragment>
        )}

        <form onSubmit={this.props.handleSubmit}>
          {!this.props.prefilledEmail ? (
            <Field
              component={Input}
              placeholder={t('checkout.account.email')}
              label={t('checkout.account.email')}
              name="email"
              onChange={this.syncWithLogin}
              validate={[required, email, this.bcgEmail]}
              autoComplete="email"
            />
          ) : (
            <Field
              component={Input}
              placeholder={t('authForms.enterpriseBuyerSignup.fullName')}
              label={t('authForms.enterpriseBuyerSignup.fullName')}
              name="name"
              type="input"
              autoComplete="off"
              validate={[required]}
            />
          )}
          <Field
            component={InputPassword}
            placeholder={t('checkout.account.passwordSignup')}
            label={t('checkout.account.passwordSignup')}
            name="password"
            type="password"
            isModal={this.props.isModal}
            withToggle={this.props.withPasswordToggle}
            autoComplete="off"
            setPasswordValidation={(value) => this.setPasswordValidation(value)}
          />
          {this.props.withNewsletterSignup && (
            <Box pt={3} pb={4}>
              <Field
                component={Checkbox}
                id="newsletterAccepted"
                label={
                  this.props.buyer
                    ? t('authForms.buyerSignup.emailOptIn')
                    : t('authForms.creatorSignup.emailOptIn')
                }
                name="newsletterAccepted"
                autoComplete="off"
              />
            </Box>
          )}

          <Captcha customError={this.props.customError} />

          <Box>
            {this.props.error && (
              <Box pb="3">
                <Text color="red50">{this.props.error}</Text>
              </Box>
            )}
            <SubmitButton
              type="submit"
              size="large"
              fullWidth
              spinner={this.props.isAuthPending}
              disabled={
                this.props.invalid ||
                this.props.isAuthPending ||
                this.state.passwordValidations[0].valid === false
              }
              data-test-id={TEST_SIGNUP_BUTTON}>
              {t('loginSignup.signupSubmit')}
            </SubmitButton>

            <Box pt={4}>
              <T
                component={Text}
                variant="title6"
                color="grey50"
                with={{
                  tos: (
                    <Link
                      rel="noopener"
                      target="_blank"
                      href={
                        this.props.buyer
                          ? '/terms/business'
                          : '/terms/photographers'
                      }>
                      <Text
                        variant="title6"
                        color="grey50"
                        textDecoration="underline">
                        {t('authForms.signup.tos')}
                      </Text>
                    </Link>
                  ),
                  privacy: (
                    <Link href="/privacy">
                      <Text
                        variant="title6"
                        color="grey50"
                        textDecoration="underline">
                        {t('authForms.signup.privacy')}
                      </Text>
                    </Link>
                  ),
                }}>
                authForms.signup.legalSubline
              </T>
            </Box>
          </Box>
        </form>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: 'signup',
  destroyOnUnmount: false,
})(SignupForm);
