/* @flow */

import { connect } from 'react-redux';
import SignupFormWrapper from './signupFormWrapper.jsx';

import {
  signupThunk,
  openModal,
  showNotificationAndDismiss,
} from '../../../helpers/actionCreators/redux';

import {
  getIsAuthPending,
  getAuthRef,
  getSearchData,
} from '../../../reducers/';

export default connect(
  (state: RootReducerState, ownProps: { authRef?: string }) => ({
    authRef: ownProps.authRef || getAuthRef(state),
    isAuthPending: getIsAuthPending(state),
    searchData: getSearchData(state),
  }),
  {
    signup: signupThunk,
    showNotification: showNotificationAndDismiss,
    openModal,
  }
)(SignupFormWrapper);
